export interface AssignedAgent {
  type: string;
  value: number;
}

export interface BatchValues {
  assignedAgent?: AssignedAgent;
  listingOwner?: number;
  owner?: number;
  status?: string;
}

export enum BatchStatus {
  IDLE = "idle",
  PENDING = "pending",
  FULFILLED = "fulfilled",
  REJECTED = "rejected"
}

export interface BatchError {
  error: number;
  success: number;
  message: string;
}

export interface BatchData {
  includes: number[];
  allListings: boolean;
  values: BatchValues;
  filters: any;
}

export interface BatchUIState {
  selectedAllItems: boolean;
  selectedAllPages: boolean;
  totalListing: number;
}

export interface BatchState {
  // Using by back-end for batch processing.
  includes: number[];
  allListings: boolean;
  values: BatchValues;
  filters: any;
  search: string | null;
  // Using by front-end for handle UI states.
  ui: BatchUIState;
  // Using by front-end for handle async tasks.
  status: BatchStatus;
  error: BatchError | null;
}

export enum BatchActionType {
  RESET_STATE = "batchEditing/resetState",
  SELECTED_ALL_ITEMS = "batchEditing/selectedAllItems",
  SELECTED_ALL_PAGES = "batchEditing/selectedAllPages",
  SELECTED_ITEM = "batchEditing/selectedItem",
  REMOVED_ITEM = "batchEditing/removedItem",
  UPDATED_TOTAL_LISTING = "batchEditing/updatedTotalListing",
  UPDATED_VALUES = "batchEditing/updatedValues",
  UPDATED_FILTERS = "batchEditing/updatedFilters",
  UPDATED_SEARCH = "batchEditing/updatedSearch",
  RESET_STATUS = "batchEditing/resetStatus",
  SUBMIT_BATCH_PENDING = "batchEditing/submitBatch/pending",
  SUBMIT_BATCH_FULFILLED = "batchEditing/submitBatch/fulfilled",
  SUBMIT_BATCH_REJECTED = "batchEditing/submitBatch/rejected"
}

export interface BatchAction {
  type: BatchActionType;
  payload: any;
}
